import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdventurerDashboard.css';

const AdventurerDashboard = ({ messages, lastSession }) => {
  const { currentUser, logout } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [avatarUrl, setAvatarUrl] = useState(currentUser?.avatar || '');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [characterSheet, setCharacterSheet] = useState(null);
  const [isLoadingCharacter, setIsLoadingCharacter] = useState(false);
  const [characterError, setCharacterError] = useState('');
  
  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL
  
  // Fetch avatar when component mounts
  useEffect(() => {
    // Track the current blob URL to clean it up later
    let currentBlobUrl = null;
    
    const fetchAvatar = async () => {
      if (!currentUser || !currentUser.id) return;
      
      try {
        const response = await fetch(`${API_URL}/avatar/${currentUser.id}`, {
          headers: {
            'x-auth-token': localStorage.getItem('auth_token')
          }
        });
        
        if (response.ok) {
          // Check if the response is JSON (base64 data) or a file
          const contentType = response.headers.get('content-type');
          
          if (contentType && contentType.includes('application/json')) {
            // Handle base64 data
            const avatarData = await response.json();
            setAvatarUrl(avatarData.avatar);
          } else {
            // Handle file URL - create a blob URL
            const blob = await response.blob();
            currentBlobUrl = URL.createObjectURL(blob);
            setAvatarUrl(currentBlobUrl);
          }
        }
      } catch (err) {
        console.error('Error fetching avatar:', err);
      }
    };
    
    fetchAvatar();
    
    // Cleanup function to revoke blob URL when component unmounts
    return () => {
      if (currentBlobUrl) {
        URL.revokeObjectURL(currentBlobUrl);
      }
    };
  }, [currentUser, API_URL]);

  // Fetch character sheet when component mounts or tab changes to character
  useEffect(() => {
    if (activeTab === 'character') {
      fetchCharacterSheet();
    }
  }, [activeTab]);

  // Fetch character sheet from server
  const fetchCharacterSheet = async () => {
    if (!currentUser || !currentUser.id) return;
    
    setIsLoadingCharacter(true);
    setCharacterError('');
    
    try {
      const response = await fetch(`${API_URL}/profile`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch profile data');
      }
      
      const profileData = await response.json();
      
      if (profileData.personalContent && profileData.personalContent.characterSheetPath) {
        // Fetch the character sheet JSON file
        const sheetResponse = await fetch(`${API_URL}/user-files${profileData.personalContent.characterSheetPath.replace(/^user_storage/, '')}`, {
          headers: {
            'x-auth-token': localStorage.getItem('auth_token')
          }
        });
        
        if (!sheetResponse.ok) {
          throw new Error('Failed to fetch character sheet');
        }
        
        const characterData = await sheetResponse.json();
        setCharacterSheet(characterData);
      } else {
        // No character sheet found
        setCharacterSheet(null);
      }
    } catch (err) {
      console.error('Error fetching character sheet:', err);
      setCharacterError(err.message || 'Failed to load character sheet');
    } finally {
      setIsLoadingCharacter(false);
    }
  };
  
  const handleCharacterSheetUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setCharacterError('File too large. Maximum size is 5MB.');
      return;
    }
    
    // Check file type
    if (file.type !== 'application/json') {
      setCharacterError('Only JSON files are allowed.');
      return;
    }
    
    setIsLoadingCharacter(true);
    setCharacterError('');
    
    try {
      // Create FormData object for file upload
      const formData = new FormData();
      formData.append('characterSheet', file);
      
      // Upload the file to the server
      console.log('Uploading character sheet to:', `${API_URL}/upload/character-sheet`);
      const response = await fetch(`${API_URL}/upload/character-sheet`, {
        method: 'POST',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: formData
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to upload character sheet');
      }
      
      // Read the file locally to display immediately
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const characterData = JSON.parse(event.target.result);
          setCharacterSheet(characterData);
        } catch (err) {
          console.error('Error parsing character sheet:', err);
          setCharacterError('Invalid character sheet format');
        }
      };
      reader.readAsText(file);
      
      setCharacterError('');
    } catch (err) {
      setCharacterError(err.message || 'An error occurred during upload');
      console.error('Error uploading character sheet:', err);
    } finally {
      setIsLoadingCharacter(false);
      
      // Reset the file input
      if (e.target) {
        e.target.value = '';
      }
    }
  };
  
  // Helper function to calculate ability modifier
  const getAbilityModifier = (score) => {
    if (!score) return "+0";
    const modifier = Math.floor((score - 10) / 2);
    return modifier >= 0 ? `+${modifier}` : `${modifier}`;
  };
  
  // Helper function to render HTML content safely
  const renderHtml = (html) => {
    if (!html) return null;
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };
  
  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setUploadError('Image too large. Maximum size is 2MB.');
      return;
    }
    
    // Check file type
    if (!file.type.match('image.*')) {
      setUploadError('Only image files are allowed.');
      return;
    }
    
    setIsUploading(true);
    setUploadError('');
    
    try {
      // Create FormData object for file upload
      const formData = new FormData();
      formData.append('avatar', file);
      
      // Upload the file to the server
      console.log('Uploading avatar to:', `${API_URL}/upload/avatar`);
      const response = await fetch(`${API_URL}/upload/avatar`, {
        method: 'POST',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: formData
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to upload avatar');
      }
      
      const data = await response.json();
      console.log('Avatar uploaded successfully:', data);
      
      // Fetch the updated avatar URL
      const avatarResponse = await fetch(`${API_URL}/avatar/${currentUser.id}`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (avatarResponse.ok) {
        // Check if the response is JSON (base64 data) or a file
        const contentType = avatarResponse.headers.get('content-type');
        
        if (contentType && contentType.includes('application/json')) {
          // Handle base64 data
          const avatarData = await avatarResponse.json();
          setAvatarUrl(avatarData.avatar);
        } else {
          // Handle file URL - create a blob URL
          const blob = await avatarResponse.blob();
          // Revoke any existing blob URL to prevent memory leaks
          if (avatarUrl && avatarUrl.startsWith('blob:')) {
            URL.revokeObjectURL(avatarUrl);
          }
          const url = URL.createObjectURL(blob);
          setAvatarUrl(url);
        }
      } else {
        console.error('Error fetching updated avatar');
      }
      
      setUploadError('');
    } catch (err) {
      setUploadError(err.message || 'An error occurred during upload');
      console.error('Error uploading avatar:', err);
    } finally {
      setIsUploading(false);
      
      // Reset the file input to allow selecting the same file again
      if (e.target) {
        e.target.value = '';
      }
    }
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  
  const handleLogout = () => {
    logout();
  };

  return (
    <div className="adventurer-dashboard">
      <div className="dashboard-header">
        <h1>First Veil</h1>
        <button 
          className="logout-button"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
      
      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => handleTabClick('overview')}
        >
          Overview
        </button>
        <button 
          className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => handleTabClick('profile')}
        >
          Profile
        </button>
        <button 
          className={`tab-button ${activeTab === 'character' ? 'active' : ''}`}
          onClick={() => handleTabClick('character')}
        >
          Character Sheet
        </button>
      </div>
      
      <div className="tab-content">
        {activeTab === 'overview' && (
          <div className="overview-tab">
            <div className="gm-messages">
              <h3>Messages from Game Master</h3>
              {messages && messages.length > 0 ? (
                <div className="message-list">
                  {messages.map(message => (
                    <div key={message.id} className="gm-message">
                      <div className="message-header">
                        <span className="message-from">{message.from}</span>
                        <span className="message-date">{formatDate(message.date)}</span>
                      </div>
                      <div 
                        className="message-content" 
                        dangerouslySetInnerHTML={{ __html: message.content }} 
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="no-messages">No messages available.</p>
              )}
            </div>
            
            <div className="last-session">
              <h3>Last Session Recap</h3>
              {lastSession ? (
                <div className="session-recap">{lastSession}</div>
              ) : (
                <p className="no-recap">Coming Soon! Session recaps will be available after your first adventure.</p>
              )}
            </div>
            
            <div className="quick-links">
              <h3>Quick Links</h3>
              <div className="links-grid">
                <a href="http://47.7.21.116:30000/join" target="_blank" rel="noopener noreferrer" className="quick-link foundry">
                  <span className="link-icon">🎲</span>
                  <span className="link-text">FoundryVTT</span>
                </a>
                <a href="https://discord.gg/SGMCCwsd" target="_blank" rel="noopener noreferrer" className="quick-link discord">
                  <span className="link-icon">💬</span>
                  <span className="link-text">Discord Channel</span>
                </a>
                <button onClick={() => handleTabClick('character')} className="quick-link character">
                  <span className="link-icon">📝</span>
                  <span className="link-text">Character Sheet</span>
                </button>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'profile' && (
          <div className="profile-tab">
            <h3>Your Adventurer Profile</h3>
            
            <div className="avatar-section">
              <div className="current-avatar">
                {avatarUrl ? (
                  <img src={avatarUrl} alt={currentUser.displayName} />
                ) : (
                  <div className="avatar-placeholder">
                    {currentUser.displayName.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
              
              <div className="avatar-upload">
                <h4>Change Avatar</h4>
                <p>Upload a small image (max 2MB) to represent your character</p>
                
                <label className="upload-button">
                  {isUploading ? 'Uploading...' : 'Choose Image'}
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleAvatarUpload} 
                    disabled={isUploading}
                  />
                </label>
                
                {uploadError && <div className="upload-error">{uploadError}</div>}
              </div>
            </div>
            
            <div className="profile-info">
              <div className="info-item">
                <div className="info-label">Username</div>
                <div className="info-value">{currentUser.username}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Display Name</div>
                <div className="info-value">{currentUser.displayName}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Role</div>
                <div className="info-value role-adventurer">Adventurer</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Member Since</div>
                <div className="info-value">{formatDate(currentUser.createdAt)}</div>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'character' && (
          <div className="character-sheet-tab">
            <h3>Character Sheet</h3>
            
            {isLoadingCharacter ? (
              <div className="loading-users">
                <div className="loading-spinner"></div>
                <p>Loading character sheet...</p>
              </div>
            ) : characterError ? (
              <div className="admin-error">
                <p>{characterError}</p>
                <button className="retry-button" onClick={fetchCharacterSheet}>
                  Retry
                </button>
              </div>
            ) : !characterSheet ? (
              <div className="no-character-sheet">
                <h4>No Character Sheet Found</h4>
                <p>Upload your D&D character sheet JSON file to view it here.</p>
                <label className="upload-character-button">
                  Upload Character Sheet
                  <input 
                    type="file" 
                    accept="application/json" 
                    onChange={handleCharacterSheetUpload} 
                  />
                </label>
              </div>
            ) : (
              <div className="character-sheet-container">
                {/* Character Header Section */}
                <div className="character-sheet-header">
                  <div className="character-portrait">
                    {characterSheet.img ? (
                      <img src={characterSheet.img} alt={characterSheet.name} />
                    ) : (
                      <div className="character-portrait-placeholder">
                        {characterSheet.name ? characterSheet.name.charAt(0).toUpperCase() : '?'}
                      </div>
                    )}
                  </div>
                  
                  <div className="character-info">
                    <h2 className="character-name">{characterSheet.name}</h2>
                    <div className="character-details">
                      <div className="character-detail">
                        <span className="detail-label">Class:</span>
                        <span className="detail-value">
                          {characterSheet.items?.find(item => item.type === 'class')?.name || "Unknown"}
                        </span>
                      </div>
                      <div className="character-detail">
                        <span className="detail-label">Race:</span>
                        <span className="detail-value">
                          {characterSheet.items?.find(item => item.type === 'race')?.name || "Unknown"}
                        </span>
                      </div>
                      <div className="character-detail">
                        <span className="detail-label">Background:</span>
                        <span className="detail-value">
                          {characterSheet.items?.find(item => item.type === 'background')?.name || "Unknown"}
                        </span>
                      </div>
                      <div className="character-detail">
                        <span className="detail-label">Level:</span>
                        <span className="detail-value">
                          {characterSheet.system?.details?.xp?.value ? 
                            Math.floor(Math.sqrt(characterSheet.system.details.xp.value / 100) + 1) : 
                            characterSheet.system?.details?.level || 
                            (characterSheet.items?.find(item => item.type === 'class')?.system?.levels || 1)}
                        </span>
                      </div>
                      <div className="character-detail">
                        <span className="detail-label">Experience:</span>
                        <span className="detail-value">
                          {characterSheet.system?.details?.xp?.value || 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Ability Scores Section */}
                <div className="character-sheet-section">
                  <h4 className="section-title">Ability Scores</h4>
                  <div className="abilities-container">
                    {characterSheet.system?.abilities && Object.entries(characterSheet.system.abilities).map(([key, ability]) => (
                      <div key={key} className={`ability-score ${ability.proficient ? 'proficient' : ''}`}>
                        <div className="ability-name">{key.toUpperCase()}</div>
                        <div className="ability-value">{ability.value}</div>
                        <div className="ability-modifier">{getAbilityModifier(ability.value)}</div>
                      </div>
                    ))}
                  </div>
                </div>
                
                {/* Saving Throws Section */}
                <div className="character-sheet-section">
                  <h4 className="section-title">Saving Throws</h4>
                  <div className="saves-container">
                    {characterSheet.system?.abilities && Object.entries(characterSheet.system.abilities).map(([key, ability]) => {
                      const abilityMod = Math.floor((ability.value - 10) / 2) || 0;
                      const profBonus = 2; // Base proficiency bonus, should be calculated based on level
                      const saveBonus = abilityMod + (ability.proficient ? profBonus : 0);
                      
                      return (
                        <div key={`save-${key}`} className="save-item">
                          <div className={`save-proficient ${ability.proficient ? 'active' : ''}`}></div>
                          <div className="save-name">
                            {key.toUpperCase()}
                          </div>
                          <div className="save-modifier">{saveBonus >= 0 ? `+${saveBonus}` : saveBonus}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Combat Stats Section */}
                <div className="character-sheet-section">
                  <h4 className="section-title">Combat Stats</h4>
                  <div className="combat-stats">
                    <div className="combat-stat">
                      <div className="stat-name">Armor Class</div>
                      <div className="stat-value">
                        {characterSheet.system?.attributes?.ac?.flat || 
                         (10 + 
                          Math.floor((characterSheet.system?.abilities?.dex?.value - 10) / 2))}
                      </div>
                    </div>
                    <div className="combat-stat">
                      <div className="stat-name">Initiative</div>
                      <div className="stat-value">
                        {getAbilityModifier(characterSheet.system?.abilities?.dex?.value)}
                      </div>
                    </div>
                    <div className="combat-stat">
                      <div className="stat-name">Speed</div>
                      <div className="stat-value">
                        {characterSheet.system?.attributes?.movement?.walk || 30} ft
                      </div>
                    </div>
                    
                    <div className="hp-container">
                      <div className="stat-name">Hit Points</div>
                      <div className="hp-bar">
                        <div 
                          className="hp-fill" 
                          style={{ 
                            width: `${Math.min(100, (characterSheet.system?.attributes?.hp?.value / 
                                               (characterSheet.system?.attributes?.hp?.max || 
                                                characterSheet.system?.attributes?.hp?.value)) * 100)}%` 
                          }}
                        ></div>
                      </div>
                      <div className="hp-text">
                        <span>Current: {characterSheet.system?.attributes?.hp?.value || 0}</span>
                        <span>Max: {characterSheet.system?.attributes?.hp?.max || 
                                    characterSheet.system?.attributes?.hp?.value || 0}</span>
                      </div>
                    </div>
                    
                    {characterSheet.system?.attributes?.death && (
                      <div className="death-saves">
                        <div className="stat-name">Death Saves</div>
                        <div className="death-saves-container">
                          <div className="death-save-group">
                            <span className="death-save-label">Successes:</span>
                            <div className="death-save-boxes">
                              {[1, 2, 3].map(i => (
                                <div 
                                  key={`success-${i}`} 
                                  className={`death-save-box ${(characterSheet.system.attributes.death.success || 0) >= i ? 'checked' : ''}`}
                                ></div>
                              ))}
                            </div>
                          </div>
                          <div className="death-save-group">
                            <span className="death-save-label">Failures:</span>
                            <div className="death-save-boxes">
                              {[1, 2, 3].map(i => (
                                <div 
                                  key={`failure-${i}`} 
                                  className={`death-save-box ${(characterSheet.system.attributes.death.failure || 0) >= i ? 'checked' : ''}`}
                                ></div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Skills Section */}
                <div className="character-sheet-section">
                  <h4 className="section-title">Skills</h4>
                  <div className="skills-container">
                    {characterSheet.system?.skills && Object.entries(characterSheet.system.skills).map(([key, skill]) => {
                      const abilityKey = skill.ability;
                      const abilityMod = Math.floor((characterSheet.system.abilities[abilityKey]?.value - 10) / 2) || 0;
                      const profBonus = 2; // Base proficiency bonus, should be calculated based on level
                      const skillMod = abilityMod + (skill.value ? profBonus : 0);
                      
                      return (
                        <div key={key} className="skill-item">
                          <div className={`skill-proficient ${skill.value ? 'active' : ''}`}></div>
                          <div className="skill-name">
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                            <span className="skill-ability">({abilityKey})</span>
                          </div>
                          <div className="skill-modifier">{skillMod >= 0 ? `+${skillMod}` : skillMod}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Tools Proficiencies Section */}
                {characterSheet.system?.tools && Object.keys(characterSheet.system.tools).length > 0 && (
                  <div className="character-sheet-section">
                    <h4 className="section-title">Tool Proficiencies</h4>
                    <div className="tools-container">
                      {Object.entries(characterSheet.system.tools).map(([key, tool]) => {
                        const abilityKey = tool.ability;
                        const abilityMod = Math.floor((characterSheet.system.abilities[abilityKey]?.value - 10) / 2) || 0;
                        const profBonus = 2; // Base proficiency bonus, should be calculated based on level
                        const toolMod = abilityMod + (tool.value ? profBonus : 0);
                        
                        return (
                          <div key={key} className="tool-item">
                            <div className="tool-name">{key.charAt(0).toUpperCase() + key.slice(1)}</div>
                            <div className="tool-modifier">{toolMod >= 0 ? `+${toolMod}` : toolMod}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {/* Proficiencies & Languages Section */}
                <div className="character-sheet-section">
                  <h4 className="section-title">Proficiencies & Languages</h4>
                  <div className="proficiencies-container">
                    {characterSheet.system?.traits?.languages && (
                      <div className="proficiency-group">
                        <div className="proficiency-type">Languages</div>
                        <div className="proficiency-list">
                          {characterSheet.system.traits.languages.value.map((language, index) => (
                            <span key={`language-${index}`} className="proficiency-item">
                              {language}
                            </span>
                          ))}
                          {characterSheet.system.traits.languages.custom && (
                            <span className="proficiency-item">{characterSheet.system.traits.languages.custom}</span>
                          )}
                        </div>
                      </div>
                    )}
                    
                    {characterSheet.system?.traits?.armorProf && (
                      <div className="proficiency-group">
                        <div className="proficiency-type">Armor</div>
                        <div className="proficiency-list">
                          {characterSheet.system.traits.armorProf.value.map((armor, index) => (
                            <span key={`armor-${index}`} className="proficiency-item">
                              {armor}
                            </span>
                          ))}
                          {characterSheet.system.traits.armorProf.custom && (
                            <span className="proficiency-item">{characterSheet.system.traits.armorProf.custom}</span>
                          )}
                        </div>
                      </div>
                    )}
                    
                    {characterSheet.system?.traits?.weaponProf && (
                      <div className="proficiency-group">
                        <div className="proficiency-type">Weapons</div>
                        <div className="proficiency-list">
                          {characterSheet.system.traits.weaponProf.value.map((weapon, index) => (
                            <span key={`weapon-${index}`} className="proficiency-item">
                              {weapon}
                            </span>
                          ))}
                          {characterSheet.system.traits.weaponProf.custom && (
                            <span className="proficiency-item">{characterSheet.system.traits.weaponProf.custom}</span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Features & Traits Section */}
                <div className="character-sheet-section">
                  <h4 className="section-title">Features & Traits</h4>
                  <div className="features-list">
                    {characterSheet.items?.filter(item => 
                      item.type === 'feat' || 
                      item.type === 'feature'
                    ).map((feature, index) => (
                      <div key={`feature-${index}`} className="feature-item">
                        <div className="feature-name">{feature.name}</div>
                        <div className="feature-description">
                          {renderHtml(feature.system?.description?.value || '')}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                
                {/* Equipment Section */}
                <div className="character-sheet-section">
                  <h4 className="section-title">Equipment</h4>
                  
                  {/* Currency */}
                  {characterSheet.system?.currency && (
                    <div className="currency-container">
                      {Object.entries(characterSheet.system.currency).map(([coin, amount]) => (
                        <div key={coin} className="currency-item">
                          <span className="currency-label">{coin.toUpperCase()}</span>
                          <span className="currency-value">{amount}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  
                  {/* Inventory Items */}
                  <div className="equipment-list">
                    {characterSheet.items?.filter(item => 
                      item.type === 'weapon' || 
                      item.type === 'equipment' || 
                      item.type === 'consumable' ||
                      item.type === 'tool' ||
                      item.type === 'backpack'
                    ).map((item, index) => (
                      <div key={`item-${index}`} className="equipment-item">
                        <div className="equipment-name-container">
                          <div className="equipment-name">{item.name}</div>
                          {item.system?.description?.value && (
                            <div className="equipment-description">
                              {renderHtml(item.system.description.value)}
                            </div>
                          )}
                        </div>
                        <div className="equipment-quantity">
                          {item.system?.quantity || 1}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                
                {/* Spells Section - Only show if character has spells */}
                {characterSheet.items?.some(item => item.type === 'spell') && (
                  <div className="character-sheet-section">
                    <h4 className="section-title">Spells</h4>
                    
                    {/* Spell Slots */}
                    {characterSheet.system?.spells && (
                      <div className="spell-slots-container">
                        {Object.entries(characterSheet.system.spells)
                          .filter(([level, data]) => level !== 'pact' && data.value > 0)
                          .map(([level, data]) => (
                            <div key={`spell-slot-${level}`} className="spell-slot-item">
                              <span className="spell-slot-label">Level {level.slice(-1)}</span>
                              <span className="spell-slot-value">
                                {data.value} / {data.max || data.override || data.value}
                              </span>
                            </div>
                          ))}
                        
                        {characterSheet.system.spells.pact?.value > 0 && (
                          <div className="spell-slot-item pact">
                            <span className="spell-slot-label">Pact Slots</span>
                            <span className="spell-slot-value">
                              {characterSheet.system.spells.pact.value} / 
                              {characterSheet.system.spells.pact.max || 
                               characterSheet.system.spells.pact.override || 
                               characterSheet.system.spells.pact.value}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Spells List */}
                    <div className="spells-container">
                      {/* Cantrips (Level 0) */}
                      <div className="spell-level-section">
                        <div className="spell-level-header">Cantrips</div>
                        <div className="spell-list">
                          {characterSheet.items
                            .filter(item => item.type === 'spell' && item.system?.level === 0)
                            .map((spell, index) => (
                              <div key={`spell-${index}`} className="spell-item">
                                <div className="spell-name">{spell.name}</div>
                                <div className="spell-description">
                                  {renderHtml(spell.system?.description?.value || '')}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      
                      {/* Leveled Spells (1-9) */}
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(level => {
                        const levelSpells = characterSheet.items.filter(
                          item => item.type === 'spell' && item.system?.level === level
                        );
                        
                        if (levelSpells.length === 0) return null;
                        
                        return (
                          <div key={`level-${level}`} className="spell-level-section">
                            <div className="spell-level-header">Level {level}</div>
                            <div className="spell-list">
                              {levelSpells.map((spell, index) => (
                                <div key={`spell-${level}-${index}`} className="spell-item">
                                  <div className="spell-name">{spell.name}</div>
                                  <div className="spell-description">
                                    {renderHtml(spell.system?.description?.value || '')}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                
                {/* Biography/Background Section */}
                {characterSheet.system?.details?.biography?.value && (
                  <div className="character-sheet-section">
                    <h4 className="section-title">Biography</h4>
                    <div className="biography-content">
                      {renderHtml(characterSheet.system.details.biography.value)}
                    </div>
                  </div>
                )}
                
                {/* Character Sheet Actions */}
                <div className="character-sheet-actions">
                  <label className="sheet-action-button">
                    Update Character Sheet
                    <input 
                      type="file" 
                      accept="application/json" 
                      onChange={handleCharacterSheetUpload} 
                      style={{ display: 'none' }}
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdventurerDashboard; 